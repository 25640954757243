
import React, { Ref } from "react";
import SEO from "../../components/seo/seo";
import { PageParams } from "../../../types";
import Hero from "../../components/hero/hero";
import Section from "../../components/air-section/air-section";

import ServiceIconWebsite from "../../images/offer/service_icon_website.svg";
import ServiceIconECommerce from "../../images/offer/service_icon_e-commerce.svg";
import ServiceIconMobile from "../../images/offer/service_icon_mobile.svg";
import ServiceIconDesign from "../../images/offer/service_icon_design.svg";
import ServiceIconDesktop from "../../images/offer/service_icon_desktop.svg";
import ServiceIconSoftware from "../../images/offer/service_icon_software.svg";
import ServiceIconConsulting from "../../images/offer/service_icon_consulting.svg";
import ServiceIconInfrastructure from "../../images/offer/service_icon_infrastructure.svg";

import "./offer.scss";
import HeaderBig from "../../components/header-big/header-big";
import CallFooter from "../../components/call-footer/call-footer";
import ScrollButton from "../../components/scroll-button/scroll-button";
import { Link } from "gatsby";

class OfferPage extends React.Component {

  language = "en";
  scrollRef: Ref<HTMLElement>;
  constructor(public params: PageParams) {
    super(params);
    this.language = params.pageContext.language;
    this.scrollRef = React.createRef();
  }

  offerItems = [
    {
      name: "Websites",
      to: "/en/websites",
      img: ServiceIconWebsite,
      alt: "Icon of a browser displaying a website",
      description: `<strong>Comprehensive website development</strong>. 
      Design, coding, administration, <abbr title="Search Engine Optimization">seo</abbr> and Google Ad Words campaigns.`,
    },
    {
      name: "E-commerce",
      to: "/en/e-commerce",
      img: ServiceIconECommerce,
      alt: "Icon of a shopping cart",
      description: `Functional and scalable <strong>e-commerce platforms</strong> created from the ground up, or based on your specific design.`,
    },
    {
      name: "Web platforms",
      to: "/en/web-platforms",
      img: ServiceIconDesktop,
      alt: "Icon of a monitor",
      description: `Modern, efficient and intuitive web platforms, with <abbr title="User Experience">UX</abbr> design best practices in mind`,
    },
    {
      name: "Web apps",
      to: "/web-apps",
      img: ServiceIconSoftware,
      alt: "Icon of a cog with coding tag",
      description: "<strong>Software development and solutions</strong> tailored to your business needs.",
    },
    {
      name: "Mobile apps",
      to: "/en/mobile-apps",
      img: ServiceIconMobile,
      alt: "Smart phone icon",
      description: `Fast, reliable and beautiful mobile apps, designed to work on any major platform: <strong>Android</strong>, <strong>iOS</strong> and <strong>Windows
      Phone</strong>`,
    },
    {
      name: "Design",
      to: "/en/design",
      img: ServiceIconDesign,
      alt: "Icon of a logo during design phase",
      description: `Branding, Digital design, 3D, package design and animation.`,
    },
    {
      name: "Consulting and business planning",
      to: "/en/consulting",
      img: ServiceIconConsulting,
      alt: "Icon of a crossroad direction sign",
      description: `<strong>Marketing campaigns</strong>, software audits, 
      <strong>technical consulting</strong>, project documentation.`,
    },
    {
      name: "Support and Administration",
      to: "/en/support",
      img: ServiceIconInfrastructure,
      alt: "Icon of a server rack",
      description: `Administration, hosting, analytics and support.`,
    },
    {
      name: "Other services",
      to: "/en/contact",
      img: ServiceIconInfrastructure,
      alt:
        "Icon",
      description: `Your project is unique, or you just haven't found the service you are looking for? Contact us, we love challenges!`,
    },
  ];
  render() {
    return (
      <>
        <SEO
          title="Offer"
          description="Websites, web apps, mobile apps and dedicated software on enterprise and local scale. ☎ +48 665 035 451"
          lang={this.language}
        />
        <Hero>
          <div className="container-xs header-padding">
            <h1>Offer</h1>
            <div className="header-subline">
              <h2 className="subtitle">
                We specialize in software development, IT outsourcing, design and online marketing
              </h2>
            </div>
            <h4>
              Our services include planning, prototyping and implementing solutions for chalenging projects.
            </h4>
          </div>
          <ScrollButton style={{
            position: "absolute",
            left: "50%",
            bottom: "5%",
            transform: "translateX(-50%)"
          }} to={this.scrollRef} />

        </Hero>

        {/* -----------------------------------------` */}
        <Section className="container" ref={this.scrollRef}>
          <HeaderBig
            tag="h2"
            span="Plan, design, develop"
            headerText="We will carry out even the most ambitious ideas"
          />
        </Section>
        <Section className="offer-section">
          <div className="container">
            <div style={{ marginLeft: "-2em", marginRight: "-2em" }}>
              <div className="r :w :c-4 :g :c-6:xs :c-12:xxs">
                {this.offerItems.map((item, index) => {
                  return (
                    <div className="item-wrapper" key={index}>
                      <div className="item r :a-t :w :c-12 ">
                        <Link
                          key={index}
                          title={item.name}
                          to={item.to}
                        >
                          <img
                            src={item.img}
                            alt={item.alt}
                            className="image"
                          />
                          <div className="content">
                            <h3
                              className="name"
                              dangerouslySetInnerHTML={{
                                __html: item.name,
                              }}
                            ></h3>
                            <p
                              className="description"
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Section>
        <CallFooter />
      </>
    );
  }
}

export default OfferPage;
